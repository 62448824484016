import { JSBI } from "@vapordex/sdk";
import { Percent } from "@vapordex/sdk";
import type { NativeCurrency, Token } from "@vapordex/sdk";
import { nativeOnChain } from "@vaporfi/utils";
import type { Address } from "viem";
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from "./chains";
import { DEFAULT_STABLE_COIN, mainnetTokens, testnetTokens } from "./tokens";
import type { ChainTokenList } from "./types";

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
	[SupportedChainId.AVALANCHE]: [
		mainnetTokens[SupportedChainId.AVALANCHE].wavax,
		mainnetTokens[SupportedChainId.AVALANCHE].usdc,
		mainnetTokens[SupportedChainId.AVALANCHE].usdt,
	],
	[SupportedChainId.AVALANCHE_TESTNET]: [
		testnetTokens[SupportedChainId.AVALANCHE_TESTNET].wavax,
		testnetTokens[SupportedChainId.AVALANCHE_TESTNET].usdc,
	],
};

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: {
	[chainId in SupportedChainId]?: { [tokenAddress: string]: Token[] };
} = {
	[SupportedChainId.AVALANCHE]: {},
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.AVALANCHE]]
 */
export const CUSTOM_BASES: {
	[chainId in SupportedChainId]?: { [tokenAddress: string]: Token[] };
} = {
	[SupportedChainId.AVALANCHE]: {},
};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
	[SupportedChainId.AVALANCHE]: [
		mainnetTokens[SupportedChainId.AVALANCHE].usdc,
	],
	[SupportedChainId.AVALANCHE_TESTNET]: [
		testnetTokens[SupportedChainId.AVALANCHE_TESTNET].wavax,
	],
	[SupportedChainId.CURTIS]: [
		testnetTokens[SupportedChainId.CURTIS].usdc,
		testnetTokens[SupportedChainId.CURTIS].usdt,
	],
	[SupportedChainId.SKALE_EUROPA_TESTNET]: [
		testnetTokens[SupportedChainId.SKALE_EUROPA_TESTNET].usdc,
	],
	[SupportedChainId.TELOS]: [mainnetTokens[SupportedChainId.TELOS].wtelos],
	[SupportedChainId.TELOS_TESTNET]: [
		testnetTokens[SupportedChainId.TELOS_TESTNET].wtelos,
	],
	[SupportedChainId.APECHAIN]: [
		mainnetTokens[SupportedChainId.APECHAIN].apeUSD,
		mainnetTokens[SupportedChainId.APECHAIN].apeETH,
		mainnetTokens[SupportedChainId.APECHAIN].wape,
	],
};

export const BIG_INT_ZERO = JSBI.BigInt(0);
export const BIG_INT_TEN = JSBI.BigInt(10);

// one basis point
export const BIPS_BASE = JSBI.BigInt(10_000);
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(
	JSBI.BigInt(100),
	BIPS_BASE,
); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(
	JSBI.BigInt(300),
	BIPS_BASE,
); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(
	JSBI.BigInt(500),
	BIPS_BASE,
); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
	JSBI.BigInt(1000),
	BIPS_BASE,
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(
	JSBI.BigInt(1500),
	BIPS_BASE,
); // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)); // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(
	JSBI.BigInt(50),
	BIPS_BASE,
);

export const ZERO_PERCENT = new Percent("0");
export const ONE_HUNDRED_PERCENT = new Percent("1");

export const BASE_FEE = new Percent(JSBI.BigInt(29), BIPS_BASE);
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE);

// AVAX
export const DEFAULT_INPUT_CURRENCY = ALL_SUPPORTED_CHAIN_IDS.reduce(
	(acc, curr) => {
		acc[curr] = nativeOnChain(curr)?.symbol;
		return acc;
	},
	{},
) as Record<SupportedChainId, NativeCurrency["symbol"]>;

// STABLE COIN
export const DEFAULT_OUTPUT_CURRENCY = {
	...ALL_SUPPORTED_CHAIN_IDS.reduce((acc, curr) => {
		acc[curr] = DEFAULT_STABLE_COIN[curr]?.address;
		return acc;
	}, {}),
	// VAPE Override
	[SupportedChainId.AVALANCHE]:
		mainnetTokens[SupportedChainId.AVALANCHE].vape?.address,
} as Record<SupportedChainId, Address>;
